<script setup lang="ts">
import Select from 'primevue/select';
import { useSiteStore } from '~/stores/site.store';
import type { ILanguage } from '~/interfaces/dto/general/language';
const { $locale } = useNuxtApp();
const runtimeConfig = useRuntimeConfig();

const settings = useSiteStore();

const languages = computed<ILanguage[]>(() =>
  settings.getConfig?.cultures
    ? settings.getConfig?.cultures
    : settings.getConfig?.Cultures,
);

const currentLocale = computed(
  () => (localeCode: string) =>
    languages.value.find((l) => l.cultureCode === localeCode),
);

const isDisabled = computed<boolean>(() => languages.value.length <= 1);

function changeLocale(locale: string) {
  switch (runtimeConfig.public.environment) {
    case 'production':
      const lang = currentLocale.value(locale);
      document.location.host = lang.url;
      break;
    default:
      $locale.changeLocale(locale);
  }
}
</script>

<template>
  <Select
    v-if="languages.length > 1"
    append-to="self"
    :options="languages"
    option-label="translation"
    option-value="cultureCode"
    :placeholder="$t('select-language')"
    :disabled="isDisabled"
    :model-value="$locale.getLocale"
    @change="changeLocale($event.value)"
  />
</template>

<style scoped lang="scss"></style>
